import * as React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import theme from '../config/theme'

import Page from '../components/layouts/Page'
import Heading from '../components/parts/Heading'
import Container from '../components/parts/Container'
import Paragraph from '../components/parts/Paragraph'
import Columns from '../components/parts/Columns'
import Column from '../components/parts/Column'
import TextLink from '../components/parts/TextLink'
import Pattern from '../components/parts/Pattern'
import SvgPattern from '../static/images/patterns/one.svg'
import Button from '../components/parts/Button'

import { ColumnViewMediaThreshold } from '../components/parts/Column/styled'

const data = [
  {
    title: 'Let’s go, Kreislaufwirtschaft.',
    description: [
      'Momentan ist unser Wirtschaftssystem linear aufgebaut. Unser Lebensstil ist auf den Konsum und einmaligen Gebrauch von Gütern ausgerichtet. Besonders nachhaltig ist das leider nicht.',
      'Es wäre doch viel schlauer, wenn wir es schaffen, Produkte länger zu nutzen und sie zu reparieren anstatt sie in den Müll zu werfen.  Und das durch Recycling neue Produkte aus ihnen entstehen, wenn man sie nicht mehr reparieren kann. Genau das will die Kreislaufwirtschaft erreichen.',
    ],
  },
  {
    title: 'Das heißt konkret ...',
    description: [
      <>
        In Deutschland werden jedes Jahr ca. 700.000 Kinder geboren. Verrückterweise werden aber
        800.000 NEUE Kinderwagen verkauft. Wir finden, das muss nicht sein. Und deswegen sagen wir:
        Nutze den Kinderwagen so lange, wie du ihn brauchst.{' '}
        <TextLink to="/refurbish-prozess">Danach bereiten wir ihn auf</TextLink> und vermieten ihn
        wieder. Das machen wir so lange, bis er kaputt und irreperabel ist. Danach wird recycelt!
      </>,
    ],
  },
  {
    title: 'Win-win-win-win …',
    description: [
      'Wir finden, das klingt alles logisch. Und es gibt dabei nur Gewinner. Dein Kind, weil wir seinen kleinen Füßchen nicht schon einen riesengroßen CO2-Fußabdruck verpassen. Dir, weil du flexibel bleibst und passend zum Alter deines Kindes einen hochwertigen Kinderwagen und/oder Kinderfahrrad zur Verfügung hast. Und unserem Planeten, weil wir seine Reccourcen schlauer nutzen.',
    ],
    showButton: true,
  },
]

const ContainerWrapper = styled.div`
  position: relative;
  padding: 0 0 7rem 0;

  ${theme.media.mdDown} {
    padding: 0 0 2rem 0;
  }
`

const MainHeadingStyled = styled(Heading)`
  margin-bottom: 0.5rem;
  padding-top: 10rem;
  font-weight: bold;
  font-size: 4rem;
  font-family: ${theme.fonts.libreBaskerville};
  line-height: 1.11;
  letter-spacing: 1.8px;

  ${theme.media.mdDown} {
    font-size: 3rem;
  }
`

const HeadingStyled = styled(Heading)`
  font-weight: bold;
  font-size: 3rem;
  font-family: ${theme.fonts.libreBaskerville};
  line-height: 1.3;
  letter-spacing: 1.25px;

  ${theme.media.mdDown} {
    font-size: 2rem;
  }
`

const ParagraphStyled = styled(Paragraph)`
  margin: 3rem 0;
  font-weight: bold;
  font-size: 1rem;
  font-family: ${theme.fonts.munkenSans};
  line-height: 1.5;
  letter-spacing: 0.4px;
`

const columnMediaThresholdSize = ColumnViewMediaThreshold.split('.')[1]

const ColumnsWrapper = styled.div`
  margin-bottom: 3rem;

  :last-child {
    margin-bottom: 0;
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const FlippedInfoContainer = styled((props) => <InfoContainer {...props} />)`
  align-items: flex-end;
  text-align: right;

  ${theme.media[columnMediaThresholdSize]} {
    align-items: flex-start;
    text-align: left;
  }
`

const StepHeadingStyled = styled(({ flipDesktop, ...props }) => <Heading {...props} />)`
  margin: 1rem 0;
  font-weight: bold;
  font-size: 3rem;
  font-family: ${theme.fonts.munkenSans};
  line-height: 1.2;
  letter-spacing: 1.25px;

  ${theme.media.mdDown} {
    font-size: 2rem;
  }
`

const StepParagraphStyled = styled(Paragraph)`
  margin-bottom: 1.5rem;
  font-weight: 500;
  font-size: 1rem;
  font-family: ${theme.fonts.munkenSans};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
`

const SmallContainerContentWrapper = styled.div`
  margin: 15rem 0;
  text-align: center;

  ${theme.media[columnMediaThresholdSize]} {
    margin: 10rem 0;
  }
`

const HeaderImageColumn = styled.div`
  & > div {
    display: none;
    ${theme.media.smUp} {
      display: block;
      margin-top: 2vh;
      transform: scale(1.2) translateX(-5%);
    }
  }
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  ${theme.media.smUp} {
    justify-content: end;
    transform: translateY(8rem);
  }
`

const Movement = () => {
  const { headerImage, graficenCirualrEconomyImage, winImage } = useStaticQuery(graphql`
    {
      headerImage: file(relativePath: { eq: "february-shooting/circularFlag.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
      graficenCirualrEconomyImage: file(
        relativePath: { eq: "february-shooting/circularVisual.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
      winImage: file(relativePath: { eq: "february-shooting/strollme_shooting_Feb_21-500.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
    }
  `)

  data[0].image = graficenCirualrEconomyImage
  data[2].image = winImage

  return (
    <Page title="Circular Economy" description="" backgroundColor={theme.palette.sage[0]}>
      <div
        style={{
          backgroundColor: theme.palette.sage[0],
        }}
      >
        <Container
          style={{
            backgroundColor: theme.palette.sage[0],
          }}
        >
          <Columns>
            <Column align="flex-end">
              <MainHeadingStyled level={1}>#strollwithus</MainHeadingStyled>
              <HeadingStyled level={2} style={{ color: theme.palette.green[0] }}>
                Du kannst Teil
              </HeadingStyled>
              <HeadingStyled level={2} style={{ color: theme.palette.green[0] }}>
                einer Bewegung sein.
              </HeadingStyled>
              <ParagraphStyled style={{ maxWidth: '35rem' }}>
                Derzeit landen viel zu viele Kinderwagen bereits nach kurzer Nutzung auf dem Müll.
                Mit deiner Hilfe können wir das ändern. Damit der kleine Mensch in deinem
                Kinderwagen auf einem lebenswerten Planeten aufwächst.
              </ParagraphStyled>
            </Column>
            <Column align="flex-end">
              <HeaderImageColumn>
                <GatsbyImage image={headerImage.childImageSharp.gatsbyImageData} alt="" />
              </HeaderImageColumn>
            </Column>
          </Columns>
        </Container>
      </div>
      <ContainerWrapper>
        <Container>
          {data.map(({
            title, description, image, showButton,
          }, index) => {
            const flipDesktop = index % 2 === 1
            const InfoContainerComponent = flipDesktop ? FlippedInfoContainer : InfoContainer
            if (!image) {
              return (
                <Container size="small" key={title}>
                  <SmallContainerContentWrapper>
                    <StepHeadingStyled level={3}>{title}</StepHeadingStyled>

                    <div>
                      {description.map((content) => (
                        <StepParagraphStyled key={content}>{content}</StepParagraphStyled>
                      ))}
                    </div>
                  </SmallContainerContentWrapper>
                </Container>
              )
            }
            return (
              <ColumnsWrapper key={title}>
                <Columns flipDesktop={flipDesktop}>
                  <Column>
                    <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt="" />
                  </Column>

                  <Column align="center">
                    <InfoContainerComponent>
                      <StepHeadingStyled level={3}>{title}</StepHeadingStyled>
                      <div>
                        {description.map((content) => (
                          <StepParagraphStyled key={content}>{content}</StepParagraphStyled>
                        ))}
                      </div>
                    </InfoContainerComponent>
                    {!!showButton && (
                      <ButtonContainer>
                        <Button to="/produkte">Zu den Kinderwagen &amp; Fahrrädern</Button>
                      </ButtonContainer>
                    )}
                  </Column>
                </Columns>
              </ColumnsWrapper>
            )
          })}
        </Container>
        <Pattern top="75%" right="0" width="75%" transformX="100%">
          <SvgPattern />
        </Pattern>
      </ContainerWrapper>
    </Page>
  )
}

export default Movement
